/********************************************************************************
Filename: np-news-feed.js
Created: 21 Nov 2022
Author: Ron Robertson
Description: Dispays a feed of news articles
Requires: <np-news-summary>
Usage Example:
<np-news-feed></np-news-feed>
********************************************************************************/

(function() {
    const template = document.createElement('template');

    template.innerHTML = `
        <style>

        #newsFeed{
          display: grid;
          grid-direction: column;
          justify-content: center;
        }
        #articlesWrapper{
          display:grid;
          grid-template-columns:repeat(1, 1fr);
          justify-content: space-between;
          gap: 20px;
          margin:0 auto;
          text-align: left;
        }
        #newsFeedTitle{
          display: inline-block;

          text-decoration: none;
          color: #000000;
          text-align: center;
          margin:0 auto 50px;
          font-size: var(--h2FontSize);
          font-family: var(--semiBoldFontFamily);
          font-weight: normal;
        }
        .ctaTrigger{
          color: #41b6e6;
          border: 1px solid #41b6e6;
          border-radius: 8px;
          padding:8px 18px;
          text-decoration: none;
          display: inline-block;
          margin: 0 auto;
          transition: all 0.3s ease-in-out;
        }
        .ctaTrigger:hover{
          background-color: #41b6e6;
          color: #ffffff;
        }

        @media screen and (min-width: 480px) {

        }
        @media screen and (min-width: 768px) {
          #articlesWrapper{
            grid-template-columns:repeat(2, 1fr);
          }
        }
        @media screen and (min-width: 960px) {
          #articlesWrapper{
            grid-template-columns:repeat(3, 1fr);
          }
          #articlesWrapper np-news-summary:nth-child(4){
            display:none;
          }
        }
        @media screen and (min-width: 1200px) {

        }
        @media screen and (min-width: 1920px) {

        }


        </style>


        <div id="newsFeed">
          <a href="news" id='newsFeedTitle'>OptiTrack News</a>
          <div id="articlesWrapper"></div>
          <a class='ctaTrigger' href="news">View More News</a>
        </div><!--END wrapper-->
    `;

    class NpNewsFeed extends HTMLElement {
        constructor() {
            /* Always call super first in constructor */
            super();

            /* bind 'this' to class in the method */
            this.fetchFeedData = this.fetchFeedData.bind(this);
            this.populateNewsFeed = this.populateNewsFeed.bind(this);
            this.sortByDate = this.sortByDate.bind(this);

            /* Use the Shadow DOM */
            this.attachShadow({ mode: 'open' });
            this.shadowRoot.appendChild(template.content.cloneNode(true));

            /* Reference to DOM Elements */
            this.newsFeedEle = this.shadowRoot.getElementById('newsFeed');
            this.articlesWrapperEle = this.shadowRoot.getElementById('articlesWrapper');

            this.apiUrl = window.location.origin + "/newadminapi/"
            if( window.location.origin.indexOf( "localdev") >= 0 ){
                this.apiUrl = "https://dev4.naturalpoint.private/newadminapi/";
            }

        } /* END constructor */

        /* Called when added to DOM */
        connectedCallback() {

            /* Set default attribute value */
            if (!this.hasAttribute('data-url')) {
                this.setAttribute('data-url', this.apiUrl+"articles");
            }
            if (!this.hasAttribute('max-articles')) {
                this.setAttribute('max-articles', 4);
            }

            /* Set Event Listeners */


        } /* END connectedCallback */

        /* Listen for when attributes change */
        /* attributes must be lowercase */
        static get observedAttributes() {
            return ['data-url','max-articles'];
        }

      /* Called when attributes change */
      attributeChangedCallback(name, oldValue, newValue) {

        if(name === 'data-url') {
            this.fetchFeedData(newValue);
        }
        if(name === 'max-articles') {
          //this.newsFeedEle.classList.add('threeCols');
        }

       }/* END attributesChangedCallback */

      /* Getting attributes */
      get dataUrl() {
        return this.getAttribute('data-url');
      }
      get maxArticles() {
        return this.getAttribute('max-articles');
      }

      /* Settinng attributes */
      set dataUrl(newValue) {
        this.setAttribute('data-url', newValue);
      }
      set maxArticles(newValue) {
        this.setAttribute('max-articles', parseInt(newValue));
      }

      /* Called when removed from DOM */
      disconnectedCallback() {

        /* Removing event listeners */
        /*
        this.myBtn.removeEventListener('click', this.myMethod);
        */
      }

        /* Method */
        //retreives data for displaying multiple articles
        fetchFeedData(dataUrl) {
            //get data for view
            if(dataUrl){
                fetch(dataUrl)
                .then(response => response.json())
                .then(data => {
                    this.responseData = data;
                    this.populateNewsFeed(data);
                });
            }
          }
        populateNewsFeed(data) {
            if(data.length > 0){

              //order by date
              data.sort(this.sortByDate);

              //clear out previous content
              this.articlesWrapperEle.innerHTML = "";

              //keeps track of the number of more articles placed
              let articleCount = 0;

              for(let i=0; i < data.length; i++) {

                let article = data[i];

                //makes sure the same article is not displayed
                if(article.id != this.articleId){

                  let newsSummary = document.createElement("np-news-summary");
                  newsSummary.setAttribute('title', article.title);
                  newsSummary.setAttribute('category', article.category);
                  newsSummary.setAttribute('date', article.date);
                  newsSummary.setAttribute('img', article.img);
                  newsSummary.setAttribute('link-target', "/news/"+article.id+"/"+article.title.replaceAll(' ', '-'));
                  newsSummary.addEventListener('click', this.onRouterLinkClick);

                  this.articlesWrapperEle.appendChild(newsSummary);
                  articleCount++;
                }

                // only display the maximum number of articles
                if(articleCount >= this.maxArticles){

                  return;
                }
              };
            }else {
                this.articlesWrapperEle.innerHTML = "<h3>Missing news feed data</h3>";
            }
        }
        //Sorts the array of objects by the date
        sortByDate(a, b) {
          if(a.date > b.date) {
              return -1;
          }
          if(a.date < b.date){
              return 1;
          }
          return 0;
        }

    } /* END class */

    window.customElements.define('np-news-feed', NpNewsFeed);

  })();
